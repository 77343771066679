export default [
  {
    path: "/admin/active_bookings",
    name: "admin-active-bookings",
    component: () => import("@/views/admin/assignments/Calendar.vue"),
  },
  {
    path: "/admin/manage_availability",
    name: "admin-manage-availability",
    component: () => import("@/views/admin/availability/Calendar.vue"),
  },
  {
    path: "/admin/timeoff",
    name: "admin-timeoff",
    component: () => import("@/views/admin/timeoff/Calendar.vue"),
  },
  {
    path: "/admin/reporting",
    name: "admin-reporting",
    component: () => import("@/views/admin/timesheets/Timesheets.vue"),
  },
  {
    path: "/admin/clients",
    name: "admin-clients",
    component: () => import("@/views/admin/client/ClientList.vue"),
  },
  {
    path: "/admin/users",
    name: "admin-users",
    component: () => import("@/views/admin/user/UserList.vue"),
  },
];
