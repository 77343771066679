export default [
  {
    path: "/hospital/calendar",
    name: "hospital-calendar",
    component: () => import("@/views/hospital/assignments/Calendar.vue"),
  },
  {
    path: "/hospital/timesheet",
    name: "hospital-timesheet",
    component: () => import("@/views/hospital/timesheets/Timesheets.vue"),
  },
];
