export default [
  {
    path: "/assistant/calendar",
    name: "assistant-calendar",
    component: () => import("@/views/assistant/assignments/Calendar.vue"),
  },
  {
    path: "/assistant/timeoff",
    name: "assistant-timeoff",
    component: () => import("@/views/assistant/timeoff/Calendar.vue"),
  },
  {
    path: "/assistant/timesheet",
    name: "assistant-timesheet",
    component: () => import("@/views/assistant/timesheets/Timesheets.vue"),
  },
];
