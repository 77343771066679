import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";

import Parse from "parse/dist/parse.min.js";

import { MonthPicker } from "vue-month-picker";
import { MonthPickerInput } from "vue-month-picker";
// Global Components
import "./global-components";

// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";

// Axios Mock Adapter
import "@/@fake-db/db";

Parse.initialize("MEDCAL-core-STAGE", "4oSr1H9YsVuN");
Parse.serverURL = "https://medcal-be.quikfox-lab.com/parse";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(require("vue-moment"));

// Composition API
Vue.use(VueCompositionAPI);

Vue.use(MonthPicker);
Vue.use(MonthPickerInput);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
